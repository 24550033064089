import anthropic from './anthropic/definition.js'
import cloudflare from './cloudflare/definition.js'
import google from './google/definition.js'
import groq from './groq/definition.js'
import openai from './openai/definition.js'
import sambanova from './sambanova/definition.js'

const definitions = {
  anthropic,
  cloudflare,
  google,
  groq,
  openai,
  sambanova,
} as const

export default Object.fromEntries(Object.entries(definitions).filter(([, d]) => d.enabled)) as typeof definitions