import type {
  Params,
} from './types.js'

const allowedSubsitutionTypes = new Set([
  'boolean',
  'string',
  'number',
] as const)

export function substitute(template: string, params: Params) {
  const remainingParams = { ...params }

  const compiledPath = template.replace(/:[_A-Za-z]+[_A-Za-z0-9]*/g, p => {
    const key = p.slice(1)
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete remainingParams[key]
    return encodeURIComponent(validate(params, key))
  })

  return [
    compiledPath,
    Object.compact(remainingParams) as Params,
  ] as const
}


function validate(params: Params, key: string) {
  if (!Object.has(params, key)) {
    throw new Error(`Missing path parameter '${String(key)}'.`)
  }

  const value = params[key]

  if (!value || !allowedSubsitutionTypes.has(typeof value)) {
    throw new TypeError(`Path parameter '${key}' must a boolean, string, or number.`)
  }

  if (typeof value === 'string' && !value.trim()) {
    throw new Error(`Path parameter '${key}' cannot be blank.`)
  }

  return value
}