import { z } from 'zod'
import type { SchemaApi } from './types.js'
import rawSchema from './rawSchema.js'

export default function schema<const T extends Record<string, z.ZodTypeAny>>(fn: (_: SchemaApi) => T): z.ZodObject<T>
export default function schema<const T extends z.ZodTypeAny>(fn: (_: SchemaApi) => T): T
export default function schema<const T>(fn: (_: SchemaApi) => T) {
  const result = rawSchema(fn)

  return Object.isObject(result) ?
    z.object(result as z.ZodRawShape) :
    result
}

