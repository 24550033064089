import { z } from 'zod'

import {
  context,
  type ZodToken,
} from './types.js'


export default function token<K extends string, V extends z.ZodTypeAny>(key: K, value: V): ZodToken<K, V>
export default function token<K extends z.ZodType, V extends z.ZodTypeAny>(key: K, value: V): ZodToken<K, V>
export default function token(key: any, value: any) {
  const properties = {
    key: typeof key === 'string' ?
      z.literal(key) :
      key,

    value,
    id: z.string().min(1),
    role: z.union([
      z.literal('user'),
      z.literal('assistant'),
    ]),
  }

  return context in value ?
    z.object({ ...properties, context: z.string().trim().min(1) }) :
    z.object(properties)
}
