import type {
  Value,
  Role,
  Properties,
  RoleProperties,
} from './types.js'

export default function token<V extends Value, R extends Role>(properties: Properties<V, R>): Dx.Ai.Token<V, R> {
  const {
    key,
    value,
    id = key,
    role,
  } = properties

  return {
    key,
    value,
    id,
    role,
  }
}


function roleToken<R extends Role>(role: R) {
  function createRoleToken<V extends Value>(properties: RoleProperties<V>, id?: string): Dx.Ai.Token<V, R>
  function createRoleToken<V extends Value>(key: string, value: V, id?: string): Dx.Ai.Token<V, R>
  function createRoleToken<V extends Value>(propertiesOrKey: string | RoleProperties<V>, valueOrId?: V | string, id?: string) {
    return typeof propertiesOrKey === 'string' ?
      token({
        key: propertiesOrKey,
        value: valueOrId as V,
        id: id ?? propertiesOrKey,
        role,
      }) :
      token({
        ...propertiesOrKey,
        id: (valueOrId ?? propertiesOrKey.id ?? propertiesOrKey.key) as string,
        role,
      })
  }

  return createRoleToken
}

token.user = roleToken('user')
token.assistant = roleToken('assistant')
token.metadata = roleToken('metadata')
token.system = roleToken('system')