import { query } from './transformations.js'

import type {
  QueryParams,
  Params,
} from './types.js'

export function join(left: string, separator: string, right: string) {
  left = stripEnd(left, separator)

  return [
    stripEnd(left, separator),
    left ? stripStart(right, separator) : right,
  ].compact().join(separator)
}

export function stripEnd(string: string, separator: string) {
  return string?.endsWith(separator) ?
    string.slice(0, -separator.length) :
    string
}

export function stripStart(string: string, separator: string) {
  return string?.startsWith(separator) ?
    string.slice(separator.length) :
    string
}


export function concat(base: string, path: string, params?: Nullable<Params>, anchor?: QueryParams) {
  return (
    join(
      join(
        join(base, '/', path),
        '?',
        query(params),
      ),
      '#',
      query(anchor),
    )
  )
}
