import define from '../define.js'

export default define({
  id: 'openai',
  name: 'OpenAI',
  enabled: true,
  models: [
    // https://artificialanalysis.ai/models/gpt-4o
    {
      type: 'chat',
      service: 'openai',
      family: 'gpt',
      name: 'gpt-4o',
      identifier: 'gpt-4o',
      contextWindow: 128_000,
      date: new Date(2024, 4),
      maxTokens: 4_096,
      modalities: new Set(['text', 'image']),
      quality: 77,
      speed: 110,
      latency: 0.37,
      costInput: 5,
      costOutput: 15,
      rateRequestsPerMin: 10_000,
      rateTokensPerMin: 30_000_000,
      rateTokensPerDay: 10_000_000_000,
      enabled: true,
    },

    // https://artificialanalysis.ai/models/gpt-4o-mini
    {
      type: 'chat',
      service: 'openai',
      family: 'gpt',
      name: 'gpt-4o-mini',
      identifier: 'gpt-4o-mini',
      contextWindow: 128_000,
      date: new Date(2024, 4),
      maxTokens: 4_096,
      modalities: new Set(['text', 'image']),
      quality: 71,
      speed: 118,
      latency: 0.41,
      costInput: 0.15,
      costOutput: 0.60,
      rateTokensPerMin: 150_000_000,
      rateTokensPerDay: 10_000_000_000,
      rateRequestsPerMin: 10_000,
      enabled: true,
    },

    // https://artificialanalysis.ai/models/gpt-4-turbo
    {
      type: 'chat',
      service: 'openai',
      family: 'gpt',
      name: 'gpt-4-turbo',
      identifier: 'gpt-4-turbo',
      contextWindow: 128_000,
      date: new Date(2023, 11),
      maxTokens: 4_096,
      modalities: new Set(['text', 'image']),
      quality: 74,
      speed: 38,
      latency: 0.57,
      costInput: 10,
      costOutput: 30,
      rateRequestsPerMin: 10_000,
      rateTokensPerMin: 2_000_000,
      rateTokensPerDay: 300_000_000,
      enabled: true,
    },

    // https://artificialanalysis.ai/models/gpt-35-turbo
    {
      type: 'chat',
      service: 'openai',
      family: 'gpt',
      name: 'gpt-3.5-turbo',
      identifier: 'gpt-3.5-turbo',
      contextWindow: 16_385,
      date: new Date(2021, 8),
      maxTokens: 4_096,
      modalities: new Set(['text']),
      quality: 52,
      speed: 86,
      latency: 0.37,
      costInput: 0.5,
      costOutput: 1.5,
      rateRequestsPerMin: 10_000,
      rateTokensPerMin: 50_000_000,
      rateTokensPerDay: 5_000_000_000,
      enabled: true,
    },

    // https://platform.openai.com/docs/guides/moderation/overview
    {
      type: 'moderation',
      service: 'openai',
      family: 'moderation',
      name: 'moderation',
      identifier: 'text-moderation-latest',
      date: new Date(2022, 8), // unknown
      maxTokens: 4_096,
      modalities: new Set(['text']),
      quality: 50,
      speed: 100, // unknown
      latency: 1, // unknown
      costInput: 0,
      rateRequestsPerMin: 1_000,
      rateTokensPerMin: 150_000,
      enabled: true,
      // categories: new Set([
      //   'hate',
      //   'hate/threatening',
      //   'harassment',
      //   'harassment/threatening',
      //   'self-harm',
      //   'self-harm/intent',
      //   'self-harm/instructions',
      //   'sexual',
      //   'sexual/minors',
      //   'violence',
      //   'violence/graphic',
      // ]),
    },

    // https://platform.openai.com/docs/guides/moderation/overview
    {
      type: 'moderation',
      service: 'openai',
      family: 'moderation',
      name: 'moderation',
      identifier: 'omin-moderation-latest',
      date: new Date(2022, 8), // unknown
      maxTokens: 4_096,
      modalities: new Set(['text', 'image']),
      quality: 100,
      speed: 100, // unknown
      latency: 1, // unknown
      costInput: 0,
      rateRequestsPerMin: 5_000,
      rateTokensPerMin: 500_000,
      enabled: true,
      // categories: new Set([
      //   'hate',
      //   'hate/threatening',
      //   'harassment',
      //   'harassment/threatening',
      //   'self-harm',
      //   'self-harm/intent',
      //   'self-harm/instructions',
      //   'sexual',
      //   'sexual/minors',
      //   'violence',
      //   'violence/graphic',
      // ]),
    },

    // https://huggingface.co/spaces/mteb/leaderboard
    {
      type: 'embedding',
      service: 'openai',
      family: 'text-embedding',
      name: 'text-embedding-3-small',
      identifier: 'text-embedding-3-small',
      maxTokens: 8191,
      dimensions: 1536,
      date: new Date(2024, 0),
      quality: 55,
      speed: 90, // unknown
      latency: 1.04, // unknown
      costInput: 0.02,
      rateRequestsPerMin: 10_000,
      rateTokensPerMin: 10_000_000,
      rateTokensPerDay: 4_000_000_000,
      enabled: true,
    },

    // https://huggingface.co/spaces/mteb/leaderboard
    {
      type: 'embedding',
      service: 'openai',
      family: 'text-embedding',
      name: 'text-embedding-3-large',
      identifier: 'text-embedding-3-large',
      maxTokens: 8191,
      dimensions: 3072,
      date: new Date(2024, 0),
      quality: 51,
      speed: 90, // unknown
      latency: 1.04, // unknown
      costInput: 0.13,
      rateRequestsPerMin: 10_000,
      rateTokensPerMin: 10_000_000,
      rateTokensPerDay: 4_000_000_000,
      enabled: true,
    },
  ],
})
