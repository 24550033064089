import {
  cloneDeep as cloneDeep_,
  defaults as defaults_,
  get as get_,
  invert as invert_,
  isEqual as isEqual_,
  mapKeys as mapKeys_,
  mapValues as mapValues_,
  mergeWith as mergeWith_,
  omit as omit_,
  pick as pick_,
  pickBy as pickBy_,
  set as set_,
  unset as unset_,
  update as update_,
} from 'lodash-es'


export const _ = Object.preventExtensions(Object.freeze({}))


/**
 * Recursively clones value.
 */
export const cloneDeep = cloneDeep_

/**
 * Returns a new object without properties having a null or undefined value.
 * Operates recursively over any nested objects. If fallbackValue is provided,
 * it will be returned if the object is empty.
 */
export function compact<T>(object: T, fallbackValue: any = {}): Compact<T> {
  if (isObject(object)) {
    const compacted: Record<string, unknown> = {}

    Object.entries(object).forEach(([key, value]) => {
      value = (compact as any)(value, null) // prevent excessively deep instantiation errors

      if (value != null) {
        compacted[key] = value
      }
    })

    return Object.keys(compacted).length ?
      compacted :
      fallbackValue
  }

  return object as any
}


const isDeeplyImmutable = new WeakSet()
const deepImmuableError = () => {
  throw new Error('Object is immutable')
}

/**
 * Recursively makes an object as immutable by calling Object.freeze,
 * Object.preventExtensions, and disabling the use of add, clear, and delete on
 * any Set and Map objects.
 */
export function deepImmutable<T extends object>(object: T): ReadonlyDeep<T> {
  if (typeof object !== 'object' || isDeeplyImmutable.has(object)) {
    return object as ReadonlyDeep<T>
  }

  isDeeplyImmutable.add(object)

  if (object instanceof Set || object instanceof Map) {
    Object.defineProperties(object, {
      add: { value: deepImmuableError },
      clear: { value: deepImmuableError },
      delete: { value: deepImmuableError },
    })
  }

  immutable(object)

  Object.getOwnPropertyNames(object).forEach(function (key) {
    const value = object[key as keyof T]

    has(object, key) &&
    value !== null &&
    deepImmutable(value as object)
  })
  
  return object as ReadonlyDeep<T>
}


/**
 * Assigns own and inherited enumerable string keyed properties of source objects
 * to the destination object for all destination properties that resolve to undefined. 
 */
export const defaults = defaults_

/**
 * Recursively assigns own and inherited enumerable string keyed properties of
 * source objects to the destination object for all destination properties that
 * resolve to undefined. 
 * Does not attempt to merge arrays.
 */
export const defaultsDeep = (object: any, ...sources: any[]) =>
  mergeWith_(object, ...sources, (left: any, right: any) =>
    Object.isObject(left) && Object.isObject(right) ?
      defaultsDeep(left, right) :
      left
  )

/**
 * Determines whether all entries in an object return true for `iteratee`.
 */
export function every<T, S extends T>(object: Record<string, T>, iteratee: (value: [string, T], index: number) => value is [string, S]): object is Record<string, S>
export function every<T>(object: Record<string, T>, iteratee: (value: [string, T], index: number) => unknown): boolean
export function every<T>(object: Record<string, T>, iteratee: (value: [string, T], index: number) => unknown): boolean {
  return Object.entries(object).every(iteratee)
}

/**
 * Iterates over each entry in the object, invoking iteratee for each entry.
 */
export function forEach<O extends object>(object: O, iteratee: (value: [keyof O & string, O[keyof O]], index: number) => unknown) {
  return (Object.entries(object) as [keyof O & string, O[keyof O]][]).forEach(iteratee)
}

/**
 * Creates a new object composed only of the properties predicate returns a
 * truthy value for.
 */
export const filter = pickBy_

/**
 * Combines map and filter in the same way flatMap combines map and flat.
 * Iterates over each entry in the oibject, invoking iteratee for each entry,
 * returning the iteratee return values that are truthy.
 */
export function filterMap<T, U>(object: Record<string, T>, iteratee: (value: [string, T], index: number) => U) {
  return Object.entries(object).filterMap(iteratee)
}

/**
 * Iterates over each entry in the object, invoking iteratee for each entry, and
 * returns a flattened list of the iteratee return values.
 */
export function flatMap<T, U>(object: Record<string, T>, iteratee: (value: [string, T], index: number) => U | ReadonlyArray<U>) {
  return Object.entries(object).flatMap(iteratee)
}

/**
 * Gets the value at path of object. If the resolved value is undefined, the
 * defaultValue is returned.
 */
export const get = get_ as {
  <const O extends object, const P extends Array<string | number>, const X = never>(object: O, path: P, defaultValue?: X): Get<O, Join<P, '.'>, X>
  <const O extends object, const P extends string | number, const X = never>(object: O, path: P, defaultValue?: X): Get<O, P, X>
}

/**
 * Checks if path is a property of object.
 */

const hasOwn = Object.hasOwn ?
  Object.hasOwn.bind(Object) :
  // eslint-disable-next-line prefer-object-has-own
  (object: object, property: PropertyKey) => Object.prototype.hasOwnProperty.call(object, property)

export function has<T extends object>(object: T, property: PropertyKey): property is keyof T {
  return hasOwn(object, property)
}

/**
 * Makes an object as immutable by calling `Object.freeze` and
 * `Object.preventExtensions`. This does not affect any objects contained
 * within – to make those immutable, use `Object.deepImmutable`..
 */
export function immutable<T extends object>(object: T): Readonly<T> {
  return Object.preventExtensions(Object.freeze(object))
}

/**
 * Creates an object composed of the inverted keys and values of object.
 */
export const invert = invert_ as <O extends Record<string, string>>(object: O) => Invert<O>

/**
 * Returns true if object has no enumerable properties, or if it is not an object.
 * The inverse of Object.isPresent.
 */
export function isEmpty(object: any) {
  return !isObject(object) || Object.keys(object).length === 0
}

/**
 * Performs a deep comparison between two values to determine if they are equivalent.
 */
export const isEqual = isEqual_

/**
 * Determines if value is a plain object.
 */
export function isObject<O>(value: O): value is true extends IsPlainObject<O> ? Extract<IfUnknown<O, O & UnknownRecord, O>, UnknownRecord> : never {
  return Boolean(value && (value.constructor === Object || Object.getPrototypeOf(value) === null))
}


/**
 * Returns true if object has enumerable properties and is an object. The
 * inverse of Object.isBlank.
 */
export function isPresent(object: unknown): object is AnyRecord<any> {
  return isObject(object) && Object.keys(object).length > 0
}

/**
 * Returns the keys of object `T` with correct types.
 */
export function typedKeys<const T extends object>(object: T) {
  return Object.keys(object) as unknown as Array<keyof T>
}

/**
 * Iterates over each entry in the object, invoking iteratee for each entry, and
 * returns a list of the iteratee return values.
 */
export function map<T, U>(object: Record<string, T>, iteratee: (value: [string, T], index: number) => U) {
  return Object.entries(object).map(iteratee)
}

/**
 * Creates an object with the same values as object and keys generated by iteratee.
 */
export const mapKeys = mapKeys_

/**
 * Creates an object with the same keys as object and values generated by iteratee.
 */
// export const mapValues = mapValues_
export function mapValues<T extends string, U, V>(object: Record<T, U>, iteratee: (value: U, key: T, index: number) => V): Record<T, V> {
  return mapValues_(object, iteratee) as any
}

/**
 * Recursively merges properties of source objects into object. Source properties
 * that are undefined are skipped if the desintation value exists. Unlike
 * lodash.merge, this doesn't merge arrays. Mutates object.
 */
export function merge<T, T1>(object: T, source: T1): DeepMerge<T, T1>
export function merge<T, T1, T2>(object: T, source1: T1, source2: T2): DeepMerge<DeepMerge<T, T1>, T2>
export function merge<T, T1, T2, T3>(object: T, source1: T1, source2: T2, source3: T3): DeepMerge<DeepMerge<DeepMerge<T, T1>, T2>, T3>
export function merge<T, T1, T2, T3, T4>(object: T, source1: T1, source2: T2, source3: T3, source4: T4): DeepMerge<DeepMerge<DeepMerge<DeepMerge<T, T1>, T2>, T3>, T4>
export function merge<T>(object: T, ...sources: any[]): T
export function merge<T>(object: T, ...sources: any[]): T {
  return mergeWith_(object, ...sources, (current: T, source: any) => {
    if (Array.isArray(source)) {
      return source
    }
  })
}

/**
 * Like merge, but accepts a customizer function that is invoked to produce the
 * merged values. If customizer returns undefined, merging is handled by the
 * method instead. Mutates object.
 */
export const mergeWith = mergeWith_

/**
 * Creates an object composed of the properties of object that are not contained
 * in paths. This is the opposite of pick() (and is considerably slower).
 */
export const omit = omit_

/**
 * Creates an object composed of only the properties of object that are contained
 * in paths. This is the opposite of omit().
 */
export const pick = pick_


export function pickValues<T extends object, U extends keyof T>(object: T, ...props: Many<U>[]): ValuesOf<Pick<T, U>>[]
export function pickValues<T>(object: T | null | undefined, ...props: Many<PropertyKey>[]): ValuesOf<Partial<T>>[]
export function pickValues(object: object, ...props: Many<PropertyKey>[]) {
  return Object.values(pick(object, ...props))
}

/**
 * Calls `iteratee` for all the entries in the object. The return value of
 * `iteratee` is the accumulated result, and is provided as an argument in the
 * next call to the `iteratee`.
 */
export function reduce<T, U>(object: Record<string, T>, iteratee: (previousValue: T, currentValue: [string, T], index: number) => U): U
export function reduce<T, U>(object: Record<string, T>, iteratee: (previousValue: U, currentValue: [string, T], index: number) => U, initialValue: U): U
export function reduce(object: Record<string, any>, iteratee: (previousValue: any, currentValue: any, index: number) => any, initialValue?: any) {
  return initialValue === undefined ?
    Object.entries(object).reduce(iteratee) :
    Object.entries(object).reduce(iteratee, initialValue)
}

/**
 * Removes and returns the key `key`.
 */
export function remove<O extends object>(object: O, key: keyof O) {
  const value = object[key]
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete object[key]
  return value
}

/**
 * Calls `iteratee` for all the entries in the object. The return value of
 * `iteratee` is the accumulated result, and is provided as an argument in the
 * next call to the `iteratee`.
 */
export const set = set_

/**
 * Converts `object` to a map.
 */
export function toMap<T>(object: Record<string, T>) {
  return new Map(Object.entries(object))
}

export function typedEntries<const T extends object>(object: T) {
  return Object.entries(object) as TypedEntries<T>
}

/**
 * Deletes a property or properties at path of object Mutates object.
 */
export const unset = unset_

/**
 * Sets the value at path of object to the result of calling fn. If any part of
 * path doesn't exist, it's created. Arrays are created for missing index
 * properties while objects are creatd for all other missing properties.
 * Mutates object.
 */
export const update = update_


declare global {
  interface ObjectConstructor {
    _: Partial<AnyRecord>,
    cloneDeep: typeof cloneDeep
    compact: typeof compact
    deepImmutable: typeof deepImmutable
    defaults: typeof defaults
    defaultsDeep: typeof defaultsDeep
    every: typeof every
    filter: typeof filter
    filterMap: typeof filterMap
    flatMap: typeof flatMap
    forEach: typeof forEach
    get: typeof get
    has: typeof has
    immutable: typeof immutable
    invert: typeof invert
    isEmpty: typeof isEmpty
    isEqual: typeof isEqual
    isObject: typeof isObject
    isPresent: typeof isPresent
    map: typeof map
    mapKeys: typeof mapKeys
    mapValues: typeof mapValues
    merge: typeof merge
    mergeWith: typeof mergeWith
    omit: typeof omit
    pick: typeof pick
    pickValues: typeof pickValues
    reduce: typeof reduce
    remove: typeof remove
    set: typeof set
    toMap: typeof toMap
    typedEntries: typeof typedEntries
    typedKeys: typeof typedKeys
    unset: typeof unset
    update: typeof update
  }
}