import define from '../define.js'

export default define({
  id: 'cloudflare',
  name: 'Cloudflare',
  enabled: false,
  models: [
    // https://artificialanalysis.ai/models/llama-3-instruct-8b
    // https://artificialanalysis.ai/models/llama-3-instruct-8b/providers
    // https://developers.cloudflare.com/workers-ai/models/llama-3-8b-instruct
    {
      type: 'chat',
      service: 'cloudflare',
      family: 'llama',
      name: 'llama-3-8b',
      identifier: '@cf/meta/llama-3-8b-instruct',
      contextWindow: 8_192,
      date: new Date(2023, 2),
      maxTokens: 8_192,
      modalities: new Set(['text']),
      quality: 64,
      
      speed: 0, // unknown
      latency: 1, // unknown
      costInput: 10, // unknown
      costOutput: 10, // unknown
      rateRequestsPerMin: 300,
      rateTokensPerMin: Infinity,
      enabled: true,
    },

    // https://developers.cloudflare.com/workers-ai/models/llava-1.5-7b-hf/
    // https://huggingface.co/llava-hf/llava-v1.6-mistral-7b-hf
    {
      type: 'chat',
      service: 'cloudflare',
      family: 'llava',
      name: 'llava-1.5',
      identifier: '@cf/llava-hf/llava-1.5-7b-hf',
      contextWindow: 4_096, // unknown
      date: new Date(2023, 8),
      maxTokens: 4_096, // unknown
      modalities: new Set(['image']),

      quality: 50, // unknown
      speed: 0, // unknown
      latency: 1, // unknown
      costInput: 10, // unknown
      costOutput: 10, // unknown
      rateRequestsPerMin: 720,
      rateTokensPerMin: Infinity,
      enabled: true,
    },

    // https://developers.cloudflare.com/workers-ai/models/llamaguard-7b-awq/
    // https://huggingface.co/TheBloke/LlamaGuard-7B-AWQ
    // https://blog.cloudflare.com/february-2024-workersai-catalog-update
    {
      type: 'moderation',
      service: 'cloudflare',
      family: 'llamaguard',
      name: 'llamaguard-7b',
      identifier: '@hf/thebloke/llamaguard-7b-awq',
      date: new Date(2022, 8),
      maxTokens: 4_096, //
      modalities: new Set(['text']),
      quality: 94,
      speed: 90, // unknown
      latency: 1.04, // unknown
      costInput: 0, // unknown
      rateRequestsPerMin: 300,
      enabled: true,
    },

    // https://developers.cloudflare.com/workers-ai/models/bge-base-en-v1.5/
    // https://huggingface.co/BAAI/bge-base-en-v1.5
    // https://huggingface.co/spaces/mteb/leaderboard
    {
      type: 'embedding',
      service: 'cloudflare',
      family: 'bge',
      name: 'bge-base-en-v1.5',
      identifier: '@cf/baai/bge-base-en-v1.5',
      maxTokens: 512,
      dimensions: 768,
      date: new Date(2023, 1),
      quality: 53,
      speed: 90, // unknown
      latency: 1.04, // unknown
      costInput: 0.014,
      rateRequestsPerMin: 3_000,
      enabled: true,
    },

    // https://developers.cloudflare.com/workers-ai/models/bge-small-en-v1.5/
    // https://huggingface.co/BAAI/bge-small-en-v1.5
    // https://huggingface.co/spaces/mteb/leaderboard
    {
      type: 'embedding',
      service: 'cloudflare',
      family: 'bge',
      name: 'bge-small-en-v1.5',
      identifier: '@cf/baai/bge-small-en-v1.5',
      maxTokens: 512,
      dimensions: 384,
      date: new Date(2023, 1),
      quality: 52,
      speed: 90, // unknown
      latency: 1.04, // unknown
      costInput: 0.003,
      rateRequestsPerMin: 3_000,
      enabled: true,
    },

    // https://developers.cloudflare.com/workers-ai/models/bge-large-en-v1.5/
    // https://huggingface.co/BAAI/bge-large-en-v1.5
    // https://huggingface.co/spaces/mteb/leaderboard
    {
      type: 'embedding',
      service: 'cloudflare',
      family: 'bge',
      name: 'bge-large-en-v1.5',
      identifier: '@cf/baai/bge-large-en-v1.5',
      maxTokens: 512,
      dimensions: 1024,
      date: new Date(2023, 1),
      quality: 54,
      speed: 90, // unknown
      latency: 1.04, // unknown
      costInput: 0.022,
      rateRequestsPerMin: 1_500,
      enabled: true,
    },
  ],
})
