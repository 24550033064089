import define from '../define.js'

export default define({
  id: 'anthropic',
  name: 'Anthropic',
  enabled: true,
  models: [
    // https://artificialanalysis.ai/models/claude-3-opus
    // https://artificialanalysis.ai/models/claude-3-opus/providers
    {
      type: 'chat',
      service: 'anthropic',
      family: 'claude',
      name: 'opus-3',
      identifier: 'claude-3-opus-20240229',
      contextWindow: 200_000,
      date: new Date(2023, 7),
      maxTokens: 4_096,
      modalities: new Set(['text', 'image']),
      quality: 70,
      speed: 26,
      latency: 1.79,
      costInput: 15,
      costOutput: 75,
      rateRequestsPerMin: 50,
      rateTokensPerMin: 20_000,
      rateTokensPerDay: 1_000_000,
      enabled: true,
    },

    // https://artificialanalysis.ai/models/claude-35-sonnet
    // https://artificialanalysis.ai/models/claude-35-sonnet/providers
    {
      type: 'chat',
      service: 'anthropic',
      family: 'claude',
      name: 'sonnet-3.5',
      identifier: 'claude-3-5-sonnet-20240620',
      contextWindow: 200_000,
      date: new Date(2024, 3),
      maxTokens: 4_096,
      modalities: new Set(['text', 'image']),
      quality: 77,
      speed: 64,
      latency: 0.93,
      costInput: 3,
      costOutput: 15,
      rateRequestsPerMin: 50,
      rateTokensPerMin: 40_000,
      rateTokensPerDay: 1_000_000,
      enabled: true,
    },

    // https://artificialanalysis.ai/models/claude-3-sonnet
    // https://artificialanalysis.ai/models/claude-3-sonnet/providers
    {
      type: 'chat',
      service: 'anthropic',
      family: 'claude',
      name: 'sonnet-3',
      identifier: 'claude-3-sonnet-20240229',
      contextWindow: 200_000,
      date: new Date(2023, 7),
      maxTokens: 4_096,
      modalities: new Set(['text', 'image']),
      quality: 57,
      speed: 54,
      latency: 0.98,
      costInput: 3,
      costOutput: 15,
      rateRequestsPerMin: 50,
      rateTokensPerMin: 40_000,
      rateTokensPerDay: 1_000_000,
      enabled: true,
    },

    // https://artificialanalysis.ai/models/claude-3-haiku
    // https://artificialanalysis.ai/models/claude-3-haiku/providers
    {
      type: 'chat',
      service: 'anthropic',
      family: 'claude',
      name: 'haiku-3',
      identifier: 'claude-3-haiku-20240307',
      contextWindow: 200_000,
      date: new Date(2023, 7),
      maxTokens: 4_096,
      modalities: new Set(['text', 'image']),
      quality: 54,
      speed: 130,
      latency: 0.47,
      costInput: 0.25,
      costOutput: 1.25,
      rateRequestsPerMin: 50,
      rateTokensPerMin: 50_000,
      rateTokensPerDay: 5_000_000,
      enabled: true,
    },
  ],
})
