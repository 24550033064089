import forwardProps from '#/styled/forwardProps'
import schema from '#/schema/index'
import token from '#/ai/tokens/token'
import urlApi from '#/URLBuilder/index'

import type {
  ImmutableContext,
  MutableContext,
} from './types.js'

import {
  immutable as immutable_,
  deepImmutable,
} from '../extensions/object.js'

export const immutable = deepImmutable({
  forwardProps,
  schema,
  token,
}) as ImmutableContext

export const mutable = immutable_({
  capture: (e: unknown) => console.error(e),
  flags: new Set<string>(),
  silence: false,
  url: urlApi(getAppUrl()),
}) as Partial<MutableContext>


function getAppUrl() {
  try {
    return __appUrl__
  } catch (e) {
    return ''
  }
}