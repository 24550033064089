import define from '../define.js'

export default define({
  id: 'google',
  name: 'Google',
  enabled: true,
  models: [
    // https://artificialanalysis.ai/models/gemini-1-5-flash
    // https://ai.google.dev/gemini-api/docs/models/gemini
    {
      type: 'chat',
      service: 'google',
      family: 'gemini',
      name: 'gemini-1.5-flash',
      identifier: 'gemini-1.5-flash-latest',
      contextWindow: 1_048_576,
      date: new Date(2024, 8),
      maxTokens: 8_196,
      modalities: new Set(['text', 'image']),
      quality: 71,
      speed: 211,
      latency: 0.35,
      costInput: 0.07,
      costOutput: 0.20,
      rateRequestsPerMin: 2_000,
      rateTokensPerMin: 4_000_000,
      enabled: true,
    },

    // https://artificialanalysis.ai/models/gemini-1-5-pro
    // https://ai.google.dev/gemini-api/docs/models/gemini
    {
      type: 'chat',
      service: 'google',
      family: 'gemini',
      name: 'gemini-1.5-pro',
      identifier: 'gemini-1.5-pro-latest',
      contextWindow: 2_097_152,
      date: new Date(2024, 8),
      maxTokens: 8_192,
      modalities: new Set(['text', 'image']),
      quality: 80,
      speed: 62,
      latency: 0.78,
      costInput: 1.25,
      costOutput: 5,
      rateRequestsPerMin: 1_000,
      rateTokensPerMin: 4_000_000,
      enabled: true,
    },
  ],
})
