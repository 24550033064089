import type { z } from 'zod'
import type { SchemaApi } from '../types.js'

export const context = Symbol('tokenSchema.context')


export type ZodToken<
  K extends string | z.ZodType = string | z.ZodType,
  V extends z.ZodTypeAny = z.ZodTypeAny,
  P extends object = EmptyObject,
> = z.ZodObject<{
  key: K extends string ? z.ZodLiteral<K> : K
  value: V
  id: z.ZodString
  role: z.ZodUnion<[
    z.ZodLiteral<'user'>,
    z.ZodLiteral<'assistant'>,
  ]>
} & P>


export type Definition = Record<string, z.ZodTypeAny>

export type Api = SchemaApi & {
  requiresContext: <T extends z.ZodTypeAny>(type: T) => T & { [context]: true }
}

export type Fn<T extends Definition> = {
  (): Schema<T, keyof T & string>
  <K extends Key<T>>(keys: K[]): Schema<T, K>
}

type Key<T extends Definition> = (keyof T & string) | ZodToken

type Schema<T extends Definition, K extends Key<T>> =
  z.ZodArray<
    z.ZodDiscriminatedUnion<'key',
      Array<
        Exclude<K, string> |
        KeyToZodToken<T, Extract<K, string>>
      >
    >
  >

type KeyToZodToken<T extends Definition, K extends (keyof T & string)> = ValuesOf<{
  [J in K]: ZodToken<J & string, T[J], (
    T[J] extends { [context]: true } ?
      { context: z.ZodString } :
      EmptyObject
  )>
}>
