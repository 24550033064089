import define from '../define.js'

export default define({
  id: 'sambanova',
  name: 'SambaNova',
  enabled: true,
  models: [
    // https://artificialanalysis.ai/models/llama-3-1-instruct-8b
    // https://artificialanalysis.ai/models/llama-3-1-instruct-8b/providers
    {
      type: 'chat',
      service: 'sambanova',
      family: 'llama',
      name: 'llama-3.1-8b',
      identifier: 'Meta-Llama-3.1-8B-Instruct',
      contextWindow: 4_096,
      date: new Date(2023, 11),
      maxTokens: 4_096,
      modalities: new Set(['text']),
      quality: 53,
      speed: 982,
      latency: 0.39,
      costInput: 0.1,
      costOutput: 0.2,
      rateRequestsPerMin: 30,
      rateTokensPerMin: Infinity,
      enabled: true,
    },

    // https://artificialanalysis.ai/models/llama-3-1-instruct-70b
    // https://artificialanalysis.ai/models/llama-3-1-instruct-70b/providers
    {
      type: 'chat',
      service: 'sambanova',
      family: 'llama',
      name: 'llama-3.1-70b',
      identifier: 'Meta-Llama-3.1-70B-Instruct',
      contextWindow: 4_096,
      date: new Date(2023, 11),
      maxTokens: 4_096,
      modalities: new Set(['text']),
      quality: 65,
      speed: 407,
      latency: 0.81,
      costInput: 0.6,
      costOutput: 1.20,
      rateRequestsPerMin: 20,
      rateTokensPerMin: Infinity,
      enabled: true,
    },

    // https://artificialanalysis.ai/models/llama-3-1-instruct-405b
    // https://artificialanalysis.ai/models/llama-3-1-instruct-405b/providers
    {
      type: 'chat',
      service: 'sambanova',
      family: 'llama',
      name: 'llama-3.1-405b',
      identifier: 'Meta-Llama-3.1-405B-Instruct',
      contextWindow: 4_096,
      date: new Date(2023, 11),
      maxTokens: 4_096,
      modalities: new Set(['text']),
      quality: 72,
      speed: 129,
      latency: 1.47,
      costInput: 5,
      costOutput: 10,
      rateRequestsPerMin: 10,
      rateTokensPerMin: Infinity,
      enabled: true,
    },
  ],
})
