import { z } from 'zod'
import literals from './literals.js'
import many from './many.js'
import normalizeModelCritiera from '$/workers/api/ai/normalizeModelCriteria'


export type InputCriteria = Dx.Schema.Input<ReturnType<typeof aiCriteriaInputSchema>>

const tupleize = <const T>(value: T[]) => value as Tupleize<T>

const rankAttrs = tupleize([
  'age',
  'contextWindow',
  'cost',
  'costInput',
  'costOutput',
  'latency',
  'quality',
  'speed',
  'maxTokens',
])

const rankDirections = tupleize([
  'asc',
  'desc',
])

const rankShorthands = tupleize(rankAttrs.flatMap(attr =>
  rankDirections.map(direction =>
    `${attr}:${direction}` as const
  )
))


export default function aiCriteriaSchema() {
  return aiCriteriaInputSchema().transform(normalizeModelCritiera)
}

export function aiCriteriaInputSchema() {
  return z.object({
    temperature: z.number().optional(),
    key: many(z.string()).optional(),
    family: many(z.string()).optional(),
    service: many(z.string()).optional(),
    name: many(z.string()).optional(),
    rank: many(
      z.union([
        z.object({
          attr: literals(rankAttrs),
          weight: z.number().optional(),
          direction: literals(rankDirections).optional(),
        }),
        literals(rankShorthands),
        literals(rankAttrs),
      ])
    ).optional(),
  })
}


