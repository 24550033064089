import models from '#/ai/apis/chat/services/models'
import type { InputCriteria } from '#/schema/aiCriteria'


const validKeys = new Set(models.chat.map(d => d.key))
const validServices = new Set(models.chat.map(d => d.service))
const validFamilies = new Set(models.chat.map(d => d.family))
const validNames = new Set(models.chat.map(d => d.name))

export default function normalizeModelCritiera(model: InputCriteria): Dx.Ai.Model.Criteria | undefined {
  if (!model || !dx.auth.permissions.has('dev')) {
    return
  }

  const {
    name,
    key,
    service,
    family,
    ...rest
  } = model

  return {
    ...rest,
    name: Array.cast(name).filter(isValidName),
    key: Array.cast(key).filter(isValidKey),
    service: Array.cast(service).filter(isValidService),
    family: Array.cast(family).filter(isValidFamily),
  }
}


function isValidKey(key: string): key is Dx.Ai.Model.Key {
  return validKeys.has(key)
}

function isValidFamily(family: string): family is Dx.Ai.Model.Family {
  return validFamilies.has(family)
}

function isValidService(service: string): service is Dx.Ai.Model.Service.Name {
  return validServices.has(service)
}

function isValidName(name: string): name is Dx.Ai.Model.Name {
  return validNames.has(name)
}