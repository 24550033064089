import { z } from 'zod'

export default function literalsSchema<const S extends readonly [any, any, ...any[]]>(values: S): z.ZodUnion<{ [K in keyof S]: z.ZodLiteral<S[K]> }> {
  const [first, second, ...rest] = values

  return z.union([
    z.literal(first),
    z.literal(second),
    ...rest.map(v => z.literal(v)),
  ]) as any
}