import type { SchemaApi, CustomSchemaApi } from './types.js'

import { z } from 'zod'
import token from './tokensSchema/token.js'
import aiCriteriaSchema from './aiCriteria.js'
import literals from './literals.js'
import many from './many.js'

export default function rawSchema<const T>(fn: (_: SchemaApi) => T) {
  const dx_: CustomSchemaApi = {
    many,
    literals,
    email() {
      return z.string().email()
    },
    nullable(type) {
      return z.preprocess(v => v || null, type.nullable())
    },
    anyObject() {
      return z.object({}).passthrough()
    },
    string: {
      present() {
        return z.string().min(1).trim()
      },
      simple() {
        return z.string().trim().min(1).transform(v => v.simplify())
      },
    },
    token,
    ai: {
      criteria: aiCriteriaSchema,
    },
  }

  const api = <S extends z.ZodRawShape>(shape: S) => z.object(shape)
  Object.assign(api, z)
  Object.assign(api, { dx: dx_ })

  return fn(api as SchemaApi)
}
