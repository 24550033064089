import { groupBy, mapValues } from 'lodash-es'
import definitions from './definitions.js'

const models = Object
  .values(definitions)
  .map(d => d.models)
  .flat()
  .filter(m => m.enabled)

const today = Date.now()

export default mapValues(groupBy(models, 'type'), typeModels =>
  typeModels.map(model => ({
    ...model,
    key: `${model.service}/${model.name}`,
    cost: model.costInput,
    age: today - model.date.getTime(),
    score: 0,
  }))
) as Record<Dx.Ai.Model.Type, Dx.Ai.Model.Scored[]>
