import type { BuildOptions } from './types.js'
import { join } from './manipulations.js'
import { query } from './transformations.js'
import URLBuilder from './URLBuilder.js'

export default function api(appOrigin: string) {
  const builder = new URLBuilder(appOrigin)

  function build(...args: [path?: string | null, options?: BuildOptions] | [options?: BuildOptions]) {
    return builder.build(...args as any)
  }

  build.current = builder.current.bind(builder)
  build.parse = builder.parse.bind(builder)
  build.isAbsolute = builder.isAbsolute.bind(builder)
  build.isRelative = builder.isRelative.bind(builder)
  build.join = join
  build.query = query
  return build
}