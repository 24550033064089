const validProps = new Set([
  // React
  'children',
  'dangerouslySetInnerHTML',
  'key',
  'ref',
  'autoFocus',
  'defaultValue',
  'defaultChecked',
  'innerHTML',
  'suppressContentEditableWarning',
  'suppressHydrationWarning',

  // DOM
  'abbr',
  'accept',
  'acceptCharset',
  'accessKey',
  'action',
  'allow',
  'allowFullScreen',
  'allowPaymentRequest',
  'allowTransparency',
  'allowUserMedia',
  'alt',
  'async',
  'autoCapitalize',
  'autoComplete',
  'autoCorrect',
  'autoFocus',
  'autoPlay',
  'autoSave',
  'capture',
  'cellPadding',
  'cellSpacing',
  'challenge',
  'charSet',
  'checked',
  'cite',
  'className',
  'colSpan',
  'cols',
  'content',
  'contentEditable',
  'contextMenu',
  'controls',
  'controlsList',
  'coords',
  'crossOrigin',
  'dateTime',
  'decoding',
  'default',
  'defer',
  'dir',
  'disablePictureInPicture',
  'disableRemotePlayback',
  'disabled',
  'download',
  'draggable',
  'encType',
  'enterKeyHint',
  'form',
  'formAction',
  'formEncType',
  'formMethod',
  'formNoValidate',
  'formTarget',
  'frameBorder',
  'headers',
  'height',
  'hidden',
  'high',
  'href',
  'hrefLang',
  'htmlFor',
  'id',
  'inert',
  'inputMode',
  'integrity',
  'is',
  'kind',
  'label',
  'lang',
  'list',
  'loading',
  'loop',
  'low',
  'marginHeight',
  'marginWidth',
  'max',
  'maxLength',
  'media',
  'mediaGroup',
  'method',
  'min',
  'minLength',
  'multiple',
  'muted',
  'name',
  'noValidate',
  'nonce',
  'open',
  'optimum',
  'pattern',
  'placeholder',
  'playsInline',
  'popover',
  'poster',
  'preload',
  'profile',
  'radioGroup',
  'readOnly',
  'referrerPolicy',
  'rel',
  'required',
  'reversed',
  'role',
  'rowSpan',
  'rows',
  'sandbox',
  'scope',
  'scoped',
  'scrolling',
  'seamless',
  'selected',
  'shape',
  'size',
  'sizes',
  'slot',
  'span',
  'spellCheck',
  'src',
  'srcDoc',
  'srcLang',
  'srcSet',
  'start',
  'step',
  'style',
  'summary',
  'tabIndex',
  'target',
  'title',
  'translate',
  'type',
  'value',
  'width',
  'wmode',
  'wrap',

  //
  'anchor',
])

const forwardPattern = /^(?:data|aria)-/
const none = new Set()

type Members<S> = S extends Set<infer K> ? K : never
type Component = React.JSXElementConstructor<any>

export default function forwardProps<C extends Component, O extends Set<keyof Dx.Props.Of<C>> = Set<keyof Dx.Props.Of<C>>>(props: AnyRecord<string>, omit: O = none as O, _c?: C) {
  const forward: Partial<Omit<Dx.Props.Of<C>, Members<O>>> = {}

  for (const [name, value] of Object.entries(props)) {
    if (!omit.has(name as any) && shouldForwardProp(name)) {
      forward[name as keyof typeof forward] = value
    }
  }

  return forward
}


function shouldForwardProp(name: string) {
  return (
    validProps.has(name) ||
    (
      name.charCodeAt(4) === 45 &&
      name.charCodeAt(3) === 97 &&
      forwardPattern.test(name)
    ) ||
    (
      name.charCodeAt(0) === 111 &&
      name.charCodeAt(1) === 110 &&
      name.charCodeAt(2) >= 65 &&
      name.charCodeAt(2) <= 90
    )
  )
}