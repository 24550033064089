import qs from 'qs'
import type { QueryParams } from './types.js'

export function query(params: QueryParams) {
  if (!params) {
    return ''
  }

  if (typeof params === 'string') {
    return params
  }

  return qs.stringify(params, {
    format: 'RFC1738',
    arrayFormat: 'comma',
  })
}
