import type { Context, MutableContext } from './types.js'
import AsyncLocalStorage from '#iso/environment/global/dx/asyncStorage.js'
import { immutable, mutable } from './core.js'

type CallbackFn<R> = () => R

const ctx = new AsyncLocalStorage<Context>()


export const fns = {
  set,
  peek,
  get,
  update,
}

export default ctx

export function set<K extends keyof MutableContext, R>(key: K, value: MutableContext[K], callback: CallbackFn<R>) {
  return update({ [key]: value }, callback)
}

export function update<R>(context: Partial<MutableContext>, callback: CallbackFn<R>) {
  const next = { ...ctx.getStore(), ...context }
  return ctx.run(Object.immutable(clone(next)), callback)
}

export function get<K extends keyof Context>(key: K): Context[K] | undefined {
  const value = peek(key)
  __assert(value != null, `'dx.${key}' is not attached (keys: ${attachedKeys().join(', ')})`)
  return value
}

export function peek<K extends keyof Context>(key: K): Context[K] | undefined {
  if (is.keyof(key, fns)) {
    return fns[key] as Context[K]
  }

  if (is.keyof(key, immutable)) {
    return immutable[key] as Context[K]
  }

  const current = ctx.getStore()

  if (current && is.keyof(key, current) && current[key] != null) {
    return current[key]
  }

  if (is.keyof(key, mutable)) {
    return mutable[key] as Context[K]
  }
}



export function clone(context: Partial<Context> = {}) {
  return { ...mutable, ...context } as Context
}


export function attachedKeys() {
  return [
    ...Object.keys(fns),
    ...Object.keys(immutable),
    ...Object.keys(mutable),
    ...Object.keys(ctx.getStore() ?? {}),
  ].uniq()
}