import define from '../define.js'

export default define({
  id: 'groq',
  name: 'Groq',
  enabled: true,
  models: [

    // https://huggingface.co/meta-llama/Llama-3.2-3B
    // https://artificialanalysis.ai/models/llama-3-2-instruct-3b
    // https://artificialanalysis.ai/models/llama-3-2-instruct-3b/providers
    {
      type: 'chat',
      service: 'groq',
      family: 'llama',
      name: 'llama-3.2-3b',
      identifier: 'llama-3.2-3b-preview',
      contextWindow: 128_000,
      date: new Date(2023, 11),
      maxTokens: 8_096,
      modalities: new Set(['text']),
      quality: 46,
        speed: 751,
        latency: 0.37,
        costInput: 0.05,
        costOutput: 0.08,
      rateRequestsPerMin: 30,
      rateRequestsPerDay: 7_000,
      rateTokensPerMin: 7_000,
      rateTokensPerDay: 500_000,
      enabled: true,
    },

    // // 
    {
      type: 'chat',
      service: 'groq',
      family: 'llama',
      name: 'llama-3.2-11b',
      identifier: 'llama-3.2-11b-text-preview',
      contextWindow: 128_000,
      date: new Date(2023, 11),
      maxTokens: 8_096,
        modalities: new Set(['text']),
        quality: 53,
        speed: 751,
        latency: 0.37,
        costInput: 0.05,
        costOutput: 0.08,
      rateRequestsPerMin: 30,
      rateRequestsPerDay: 7_000,
      rateTokensPerMin: 7_000,
      rateTokensPerDay: 500_000,
      enabled: true,
    },

    // https://huggingface.co/meta-llama/Llama-3.2-11B-Vision
    // https://artificialanalysis.ai/models/llama-3-2-instruct-11b-vision
    // https://artificialanalysis.ai/models/llama-3-2-instruct-11b-vision/providers
    {
      type: 'chat',
      service: 'groq',
      family: 'llama',
      name: 'llama-3.2-11b-vision',
      identifier: 'llama-3.2-11b-vision-preview',
      contextWindow: 128_000,
      date: new Date(2023, 11),
      maxTokens: 8_096,
      modalities: new Set(['image']),
      quality: 53,
        speed: 250,
        latency: 0.44,
        costInput: 0.05,
        costOutput: 0.08,
      rateRequestsPerMin: 30,
      rateRequestsPerDay: 7_000,
      rateTokensPerMin: 7_000,
      rateTokensPerDay: 500_000,
      enabled: true,
    },

    // // 
    // {
    //   type: 'chat',
    //   service: 'groq',
    //   family: 'llama',
    //   name: 'llama-3.2-90b-text',
    //   identifier: 'llama-3.2-90b-text-preview',
    //     contextWindow: 8_096,
    //     date: new Date(2023, 11),
    //     maxTokens: 8_096,
    //     modalities: new Set(['text']),
    //     quality: 66,
    //     speed: 751,
    //     latency: 0.37,
    //     costInput: 0.05,
    //     costOutput: 0.08,
    //   rateRequestsPerMin: 30,
    //   rateRequestsPerDay: 7_000,
    //   rateTokensPerMin: 7_000,
    //   rateTokensPerDay: 500_000,
    //   enabled: true,
    // },


    // https://artificialanalysis.ai/models/llama-3-1-instruct-8b
    // https://artificialanalysis.ai/models/llama-3-1-instruct-8b/providers
    {
      type: 'chat',
      service: 'groq',
      family: 'llama',
      name: 'llama-3.1-8b',
      identifier: 'llama-3.1-8b-instant',
      contextWindow: 8_096,
      date: new Date(2023, 11),
      maxTokens: 8_096,
      modalities: new Set(['text']),
      quality: 53,
      speed: 751,
      latency: 0.37,
      costInput: 0.05,
      costOutput: 0.08,
      rateRequestsPerMin: 30,
      rateRequestsPerDay: 14400,
      rateTokensPerMin: 20_000,
      rateTokensPerDay: 500_000,
      enabled: true,
    },

    // https://artificialanalysis.ai/models/llama-3-1-instruct-70b
    // https://artificialanalysis.ai/models/llama-3-1-instruct-70b/providers
    {
      type: 'chat',
      service: 'groq',
      family: 'llama',
      name: 'llama-3.1-70b',
      identifier: 'llama-3.1-70b-versatile',
      contextWindow: 8_096,
      date: new Date(2023, 11),
      maxTokens: 8_096,
      modalities: new Set(['text']),
      quality: 65,
      speed: 250,
      latency: 0.44,
      costInput: 0.59,
      costOutput: 0.79,
      rateRequestsPerMin: 30,
      rateRequestsPerDay: 14400,
      rateTokensPerMin: 30_000,
      rateTokensPerDay: 500_000,
      enabled: true,
    },

    // https://artificialanalysis.ai/models/llama-3-instruct-8b
    // https://artificialanalysis.ai/models/llama-3-instruct-8b/providers
    {
      type: 'chat',
      service: 'groq',
      family: 'llama',
      name: 'llama-3-8b',
      identifier: 'llama3-8b-8192',
      contextWindow: 8_192,
      date: new Date(2023, 2),
      maxTokens: 8_192,
      modalities: new Set(['text']),
      quality: 46,
      speed: 1202,
      latency: 0.28,
      costInput: 0.05,
      costOutput: 0.08,
      rateRequestsPerMin: 30,
      rateRequestsPerDay: 14400,
      rateTokensPerMin: 30_000,
      rateTokensPerDay: 500_000,
      enabled: true,
    },

    // https://artificialanalysis.ai/models/llama-3-instruct-70b
    // https://artificialanalysis.ai/models/llama-3-instruct-70b/providers
    {
      type: 'chat',
      service: 'groq',
      family: 'llama',
      name: 'llama-3-70b',
      identifier: 'llama3-70b-8192',
      contextWindow: 8_192,
      date: new Date(2023, 11),
      maxTokens: 8_192,
      modalities: new Set(['text']),
      quality: 62,
      speed: 318,
      latency: 0.22,
      costInput: 0.59,
      costOutput: 0.79,
      rateRequestsPerMin: 30,
      rateRequestsPerDay: 14400,
      rateTokensPerMin: 6_000,
      rateTokensPerDay: 500_000,
      enabled: true,
    },

    // https://artificialanalysis.ai/models/gemma-2-9b
    // https://artificialanalysis.ai/models/gemma-2-9b/providers
    {
      type: 'chat',
      service: 'groq',
      family: 'gemma',
      name: 'gemma2-9b',
      identifier: 'gemma2-9b-it',
      contextWindow: 8_192,
      date: new Date(2024, 5),
      maxTokens: 8_192,
      modalities: new Set(['text']),
      quality: 46,
      speed: 668,
      latency: 0.19,
      costInput: 0.2,
      costOutput: 0.2,
      rateRequestsPerMin: 30,
      rateRequestsPerDay: 14400,
      rateTokensPerMin: 14_000,
      rateTokensPerDay: 500_000,
      enabled: true,
    },

    // https://huggingface.co/meta-llama/Llama-Guard-3-8B
    {
      type: 'moderation',
      service: 'groq',
      family: 'llamaguard',
      name: 'llamaguard-3',
      identifier: 'llama-guard-3-8b',
      date: new Date(2022, 8),
      maxTokens: 4_096, //
      modalities: new Set(['text']),
      quality: 50,
      speed: 100, // unknown
      latency: 1, // unknown
      costInput: 0.05, // unknown
      rateRequestsPerMin: 30,
      rateRequestsPerDay: 14400,
      rateTokensPerMin: 150_000,
      rateTokensPerDay: 500_000,
      enabled: true,
    },
  ],
})
