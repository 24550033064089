// This is a shim for the browser. It doesn't work anything like the node
// equivalent but that doesn't matter for our purposes.
export default class AsyncLocalStorage<T> {
  #value: T | null = null

  run<R>(next: T, callback: () => R) {
    this.#value = next
    return callback()
  }

  getStore() {
    return this.#value
  }
}