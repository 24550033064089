import type {
  Params,
} from './types.js'

import {
  stripEnd,
  join,
} from './manipulations.js'


export default class EnhancedURL extends URL {
  readonly anchor: string
  readonly domain: string
  readonly hostPath: string
  readonly params: Params
  readonly query: string

  constructor(url: string | URL, base?: string) {
    super(url, base)
    this.anchor = this.hash.slice(1)
    this.domain = this.hostname
    this.params = [...this.searchParams].toObject() as Params
    this.query = this.search
    this.hostPath = stripEnd(join(this.host, '/', this.pathname), '/')
  }

  toString() {
    return this.href
  }
}
