import EnhancedURL from './EnhancedURL.js'

import type {
  BuildOptions,
  OriginOption,
  Params,
} from './types.js'

import { concat } from './manipulations.js'
import { substitute } from './substitutions.js'

export default class URLBuilder {
  #defaultOrigin: string

  constructor(appOrigin: string) {
    this.#defaultOrigin = (globalThis as any)?.location?.origin ?? appOrigin
  }

  current() {
    const current = this.parse((globalThis as any)?.location ?? this.#defaultOrigin)
    __assert(current, 'Could not get current URL')
    return current
  }

  build(path?: string | null, options?: BuildOptions): string
  build(options?: BuildOptions): string
  build(base?: string | null | BuildOptions, options_?: BuildOptions) {
    let [path, options] =
      base == null || typeof base === 'string' ? [base as Nullable<string>, options_ ?? {}] :
      [null, base ?? {}]

    const {
      '#': anchor,
      origin,
      ...params
    } = options

    if (path && this.isAbsolute(path)) {
      return concat('', path, params, anchor)
    }

    path ??= this.current().pathname

    const substitutions = this.#substitutions(path, params)
    const [compiled, remaining] = substitute(path, substitutions)

    const resolvedOrigin = this.#resolveOrigin(origin)
    const cat = concat(resolvedOrigin, compiled)
    const parsed = this.parse(cat)

    return parsed ?
      concat(parsed.origin, parsed.pathname, { ...parsed.params, ...remaining }, anchor ?? parsed.anchor) :
      concat(resolvedOrigin, compiled, remaining, anchor)
  }

  parse(value?: string | URL | null) {
    if (value == null) {
      return null
    }

    try {
      return typeof value === 'string' && value.startsWith('/') ?
        new EnhancedURL(value, this.#defaultOrigin) :
        new EnhancedURL(value)

    } catch (e) {
      return null
    }
  }

  isAbsolute(url: string) {
    return /^(?:[a-z]:\/\/|(?:mailto|tel):)/.test(url)
  }

  isRelative(url: string) {
    return !this.isAbsolute(url)
  }

  #resolveOrigin(url: OriginOption) {
    return (
      url === true ? this.#defaultOrigin :
      url === false ? '' :
      url ?? ''
    )
  }

  #substitutions(template: string, params: Params): Params {
    if (!('id' in params)) {
      return params
    }

    const segments = template
      .split('/')
      .compact()
      .filter(s => s.startsWith(':'))

    if (segments.includes(':id')) {
      return params
    }

    const idParam = segments.find(s => s.endsWith('Id'))?.replace(/^:/, '')

    if (idParam) {
      return Object.compact({
        ...params,
        [idParam]: params[idParam] || params.id,
        id: undefined,
      })
    }
    
    return params
  }
}